import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Typography, Box, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, FormControl, InputLabel, Select, MenuItem, Pagination } from '@mui/material';
import BasePage from '../../../../components/page_base/PageBase';
import { loadLastProfessionals } from '../../professional.reducer';
import theme from '../theme';
import HeadPage from '../../../../components/title/Title';
import { parseDateString } from '../../../utils/Utils';

const LastProfessionals = () => {
    const professionalState = useSelector((state) => state.professionalState);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const offset = (currentPage - 1) * itemsPerPage;
        dispatch(loadLastProfessionals(itemsPerPage, offset)).then(() => {
            setLoading(false);
        });
    }, [dispatch, currentPage, itemsPerPage]);

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleItemsPerPageChange = (event) => {
        const newItemsPerPage = parseInt(event.target.value, 10);
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1); // Reinicia para a primeira página ao alterar o número de itens por página
    };

    const data = professionalState.lastProfessionals && professionalState.lastProfessionals.map((item) => ({
        ID: item.oid || 0,
        Data: parseDateString(item.created_at)?.toLocaleDateString("pt-BR") || '',
        Nome: item.nome || '',
        Email: item.email || '',
        Profissões: item.profissoes ? item.profissoes.map(prof => prof.nome).join(', ') : '',
        Telefone: item.telefone || '',
        Cidade: item.cidade ? item.cidade.nome : '',

    }));

    const filteredData = data.filter(item =>
        Object.values(item).some(value =>
            String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = filteredData.slice(startIndex, endIndex);

    const columnNames = data.length > 0 ? Object.keys(data[0]) : [];

    return (
        <BasePage theme={theme} loading={loading}>

            <HeadPage title={`Pesquisar pelos últimos profissionais cadastrados`} />

            {professionalState.lastProfessionals && professionalState.lastProfessionals.length > 0 && (
                <div>
                    <Typography variant="h5" align="center" gutterBottom>
                        {filteredData.length === 0 ? 'Nenhum registro encontrado' : `Resultado: (${filteredData.length} registros)`}
                    </Typography>
                    <Box boxShadow={3} borderRadius={5} p={2} mb={2} style={{ backgroundColor: 'white' }}>
                        <TextField
                            label="Pesquisar"
                            variant="outlined"
                            onChange={(e) => setSearchTerm(e.target.value)}
                            fullWidth
                        />
                    </Box>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columnNames.map((columnName) => (
                                        <TableCell key={columnName} variant="head" style={{ backgroundColor: '#1976d2', color: 'white' }}>
                                            {columnName.charAt(0).toUpperCase() + columnName.slice(1)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedData.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={columnNames.length} align="center">Nenhum registro encontrado</TableCell>
                                    </TableRow>
                                ) : (
                                    paginatedData.map((row, index) => (
                                        <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                            {columnNames.map((columnName) => (
                                                <TableCell key={columnName}>{row[columnName]}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={3} mb={3} marginBottom={6}>
                        <FormControl variant="outlined" style={{ minWidth: 125 }}>
                            <InputLabel>Linhas por página</InputLabel>
                            <Select
                                value={itemsPerPage}
                                onChange={handleItemsPerPageChange}
                                label="Linhas por página"
                            >
                                {[10, 20, 50, 100].map((rowsPerPageOption) => (
                                    <MenuItem key={rowsPerPageOption} value={rowsPerPageOption}>
                                        {rowsPerPageOption}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Pagination
                            count={Math.ceil(filteredData.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                </div>
            )}
        </BasePage>
    );
}

export default LastProfessionals;
