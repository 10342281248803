import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem, IconButton, Pagination } from '@mui/material';

import BasePage from '../../../../components/page_base/PageBase';
import DataTable from '../../../../components/dataTable/DataTable';

import { loadBudgetByMonth } from '../../budget.reducer';

import theme from './theme';

const ListBudgets = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const budgets = useSelector((state) => state.budgetState.budgets || []);
    const errorMessage = useSelector((state) => state.budgetState.errorMessage);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedMonth, setSelectedMonth] = useState('January');
    const [budgetsPerPage, setBudgetsPerPage] = useState(10);

    useEffect(() => {
        setLoading(false);
        dispatch(loadBudgetByMonth(selectedMonth, currentPage, budgetsPerPage));
    }, [selectedMonth, currentPage, budgetsPerPage, dispatch]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleBudgetsPerPageChange = (event) => {
        setBudgetsPerPage(event.target.value);
        setCurrentPage(1);
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
        setCurrentPage(1);
    };

    const handleBack = () => {
        navigate(-1);
    };



    // Preparar dados para DataTable
    const dataTableContent = budgets && budgets.map(budget => ({
        Nome: budget.name,
        Email: budget.email,
        Telefone: budget.telephone,
        Descrição: budget.description,
    }));

    const totalBudgets = budgets ? budgets.length : 0;
    const totalPages = Math.ceil(totalBudgets / budgetsPerPage);

    const page = (
        <Box sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <IconButton onClick={handleBack} sx={{ mr: 2 }}>
                    <ArrowBackIcon />
                </IconButton>
                {/* <Typography variant="h4" gutterBottom>
                    Lista de Orçamentos
                </Typography> */}
            </Box>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 2 }}>
                <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel>Mês</InputLabel>
                    <Select value={selectedMonth} onChange={handleMonthChange} label="Mês">
                        <MenuItem value="January">Janeiro</MenuItem>
                        <MenuItem value="February">Fevereiro</MenuItem>
                        <MenuItem value="March">Março</MenuItem>
                        <MenuItem value="April">Abril</MenuItem>
                        <MenuItem value="May">Maio</MenuItem>
                        <MenuItem value="June">Junho</MenuItem>
                        <MenuItem value="July">Julho</MenuItem>
                        <MenuItem value="August">Agosto</MenuItem>
                        <MenuItem value="September">Setembro</MenuItem>
                        <MenuItem value="October">Outubro</MenuItem>
                        <MenuItem value="November">Novembro</MenuItem>
                        <MenuItem value="December">Dezembro</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            {errorMessage ? (
                <Typography color="error">{errorMessage}</Typography>
            ) : (
                <DataTable
                    data={dataTableContent}
                    page={currentPage}
                    setPage={setCurrentPage}
                    itemsPerPage={budgetsPerPage}
                    handleItemsPerPageChange={handleBudgetsPerPageChange}
                    totalItems={totalBudgets}
                />
            )}

            {totalPages > 1 && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </Box>
            )}
        </Box>
    );

    return (
        <BasePage theme={theme} loading={loading}>
            {page}
        </BasePage>
    );
};

export default ListBudgets;
