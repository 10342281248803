
import { useEffect, useState } from 'react';

import { useMediaQuery } from '@mui/material';


import BasePage from '../../../components/page_base/PageBase';
import Carrossel from '../../../components/carrossel/Carrosel';
import ImageWithText from '../../../components/image-with-text/ImageWithText';
import ListExpostitors from '../../../components/expositor/ListExpositor';
import IncentiveMessage from '../../../components/incentive-message/IncentiveMessage';
import ListSixProfessions from '../../../components/list-professions/ListProfessions';

// import AnuncieAqui from '../../../assets/ANUNCIE_AQUI_BAIXA.jpeg';
import AnuncieAqui from '../../../assets/DESKTOP1.jpeg';
import EncontreProfissional from '../../../assets/DESKTOP2.jpeg';

import AnuncieAquiMobile from '../../../assets/MOBILE1.jpeg';
import EncontreProfissionalMobile from '../../../assets/MOBILE2.jpeg';


import pintor from '../../../assets/pintor.jpeg';
import pedreiro from '../../../assets/pedreiro.jpeg';
import encanador from '../../../assets/encanador.jpeg';
import serralheiro from '../../../assets/serralheiro.jpeg';
import carpinteiro from '../../../assets/carpinteiro.jpeg';
import eletricista from '../../../assets/eletricista.jpeg';

import { theme } from './theme';

const HomePage = () => {

    const isMobile = useMediaQuery('(max-width:600px)');

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, [setLoading]);

    const carouselItems = isMobile
        ? [
            {
                img: AnuncieAquiMobile,
                title: 'Anuncie sua empresa conosco',
                description: 'Anuncie sua empresa conosco',
            },
            {
                img: EncontreProfissionalMobile,
                title: 'Encontre o profissional que você precisa',
                description: 'Encontre o profissional que você precisa',
            },
        ]
        : [
            {
                img: AnuncieAqui,
                title: 'Anuncie sua empresa conosco',
                description: 'Anuncie sua empresa conosco',
            },
            {
                img: EncontreProfissional,
                title: 'Encontre o profissional que você precisa',
                description: 'Encontre o profissional que você precisa',
            },
        ];


    const professions = [
        { imageUrl: pedreiro, name: 'Pedreiro' },
        { imageUrl: pintor, name: 'Pintor' },
        { imageUrl: serralheiro, name: 'Serralheiro' },
        { imageUrl: encanador, name: 'Encanador' },
        { imageUrl: carpinteiro, name: 'Carpinteiro' },
        { imageUrl: eletricista, name: 'Eletricísta' },
    ];

    const expositors = [
        {
            logo: 'https://via.placeholder.com/150', // Substitua pelo link real do logo
            name: 'Empresa A',
            description: 'Descrição da Empresa A.',
        },
        {
            logo: 'https://via.placeholder.com/150', // Substitua pelo link real do logo
            name: 'Empresa B',
            description: 'Descrição da Empresa B.',
        },
        {
            logo: 'https://via.placeholder.com/150', // Substitua pelo link real do logo
            name: 'Empresa C',
            description: 'Descrição da Empresa C.',
        },
    ];


    const page = (
        <>
            {/* Estilos globais para o botão do WhatsApp */}
            <style>
                {`
                    .whatsapp-button {
                        position: fixed;
                        bottom: 20px;
                        right: 20px;
                        z-index: 9999;
                        // border: 1px solid red;
                        display: block !important;
                    }
                    .whatsapp-button img {
                        width: 160px;
                        height: 160px;
                    }
                    @media (max-width: 480px) {
                        .whatsapp-button img {
                            width: 80px; /* Tamanho para telas muito pequenas */
                            height: 80px; /* Tamanho para telas muito pequenas */
                        }
                    }
                    @media (max-width: 360px) {
                        .whatsapp-button img {
                            width: 60px; /* Tamanho para telas super pequenas */
                            height: 60px; /* Tamanho para telas super pequenas */
                        }
                    }
                `}
            </style>


            <Carrossel carouselItems={carouselItems} isMobile={isMobile} />

            <IncentiveMessage link="/search-professionals" />


            <ImageWithText
                imageUrl="./images/about-img.jpg"
                title="Seja reconhecido e esteja à frente."
                text={`Destaque-se na indústria da construção! 
                Cadastre-se em nossa plataforma inovadora e 
                seja visto por uma variedade de oportunidades 
                de negócios e projetos desafiadores. 
                Seja reconhecido como um líder em sua área, 
                conecte-se e expanda suas oportunidades de negócios. 
                Cadastre-se hoje e abra portas para o sucesso na construção! 
                Lembre-se : Quem não é visto, não é lembrado!`}
                link="/add-professionals"
                text_botton="Cadastre-se"
            />

            <ImageWithText
                imageUrl="./images/professional-img.png"
                title="Encontre o Profissional"
                text={`"Construir sem aperto,
                Procurar profissionais, é nosso acerto.
                Na construção, economia é fato,
                Construir mais barato, nosso contrato."
                A sua satisfação, é nosso contrato!`}
                link="/search-professionals"
                text_botton="Pesquisar Profissionais"
            />



            <ListSixProfessions
                title='Profissões'
                data={professions}
            />

            {/*  Esse box mostra as futuras empresas parceiras da plataforma */}
            {/*
            <ListExpostitors
                expositors={expositors}
                title={"Empresas Parceiras"}
            />
            /*}

            {/* <BoxWithText
                link='/login'
                text='Testando Box com texto'
                textButton='Texto do botão'
                title='Box com Texto'

            /> */}

            <div className="whatsapp-button">
                <a href="https://api.whatsapp.com/send?phone=5514991665023&text=Olá! Gostaria de mais informações." target="_blank" rel="noopener">
                    <img src="./images/btn_whatzap/btn_whatzap_6.png" alt="WhatsApp" style={{ width: '160px', height: '160px' }} />
                </a>
            </div>

        </>
    )

    return (
        <BasePage
            theme={theme}
            loading={loading}
        >
            {page}
        </BasePage>
    );
};

export default HomePage;
