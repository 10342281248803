import React from 'react';
import { Card, CardContent, CardMedia, Typography, Grid } from '@mui/material';

const ExpositorCard = ({ logo, name, description }) => {
    return (
        <Card elevation={3} style={{ maxWidth: 345, margin: '20px auto', borderRadius: '8px' }}>
            <CardMedia
                component="img"
                alt={name}
                height="140"
                image={logo}
                style={{ objectFit: 'contain', padding: '20px' }}
            />
            <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                    {name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default ExpositorCard;
