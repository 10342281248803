import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const IncentiveMessage = ({ link }) => {
    return (
        <Box sx={{ my: 4, p: 4, border: '1px solid #ccc', borderRadius: 2, textAlign: 'center' }}>
            <Typography variant="h5" gutterBottom>
                Encontre os melhores profissionais para seu projeto!
            </Typography>
            {/* <Typography variant="body1">
                Nossa plataforma conecta você a profissionais qualificados na área da construção civil.
                Não perca tempo, comece agora!
            </Typography> */}
            <Link to={link} style={{ textDecoration: 'none' }}>

                <Button variant="contained" color="primary" sx={{ mt: 2 }}>Comece sua Busca</Button>
            </Link>
        </Box>
    );
};

export default IncentiveMessage;