import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItemButton, ListItemText, Divider, Box } from '@mui/material';

import storage from '../store/storage';
import authorization from '../services/authorization';

import PrivacyPolicyModal from './modal/PrivatePolicyModal';


const Sidebar = ({ mobileOpen, handleDrawerToggle }) => {

    const isAuthenticated = authorization.isAuthenticated();
    const user = storage.getUser();
    const isUserAdm = user && user.profile === "administrador";

    const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
    const dispatch = useDispatch();

    const logoffHandler = () => {
        dispatch(authorization.logout());
        window.location.reload();
    }

    const handlePrivacyPolicyOpen = (e) => {
        e.preventDefault();
        setPrivacyPolicyOpen(true);
        handleDrawerToggle();
    }


    const drawer = (
        <div>
            <List>
                <ListItemButton component={Link} to="/" onClick={handleDrawerToggle}>
                    <ListItemText primary="Início" />
                </ListItemButton>
                <ListItemButton component={Link} to="https://construirmaisbaratoshop.com.br/" onClick={handleDrawerToggle}>
                    <ListItemText primary="Marketplace" />
                </ListItemButton>
                <ListItemButton component={Link} to="/search-professionals" onClick={handleDrawerToggle}>
                    <ListItemText primary="Pesquisar Profissional" />
                </ListItemButton>
                <ListItemButton onClick={handlePrivacyPolicyOpen}>
                    <ListItemText primary="Política de Privacidade" />
                </ListItemButton>
                {!isAuthenticated ? (
                    <>
                        <ListItemButton component={Link} to="/add-professionals"
                            text_botton="Cadastre-se" >
                            <ListItemText primary="Cadastre-se" />
                        </ListItemButton>
                        <ListItemButton component={Link} to="/login" onClick={handleDrawerToggle}>
                            <ListItemText primary="Login" />
                        </ListItemButton>
                    </>
                ) : (
                    <>
                        {isUserAdm ? (
                            <ListItemButton component={Link} to="/controll-panel" onClick={handleDrawerToggle}>
                                <ListItemText primary="Painel de Controle" />
                            </ListItemButton>

                        ) : (
                            <ListItemButton component={Link} to="/dashboard-user" onClick={handleDrawerToggle}>
                                <ListItemText primary="Área do Profissional" />
                            </ListItemButton>
                        )}

                        <ListItemButton onClick={() => { handleDrawerToggle(); logoffHandler(); }} >
                            <ListItemText primary="Sair" />
                        </ListItemButton>
                    </>
                )
                }
            </List>
            <Divider />
        </div>
    );

    return (
        <>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                        C + B | Construir mais barato
                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <List sx={{ display: 'flex', flexDirection: 'row', p: 0 }}>
                            <ListItemButton component={Link} to="/">
                                <ListItemText primary="Início" />
                            </ListItemButton>
                            <ListItemButton component={Link} to="https://construirmaisbaratoshop.com.br/" onClick={handleDrawerToggle}>
                                <ListItemText primary="Marketplace" />
                            </ListItemButton>
                            <ListItemButton component={Link} to="/search-professionals">
                                <ListItemText primary="Pesquisar Profissional" />
                            </ListItemButton>
                            <ListItemButton onClick={handlePrivacyPolicyOpen}>
                                <ListItemText primary="Política de Privacidade" />
                            </ListItemButton>
                            {!isAuthenticated ? (
                                <>
                                    <ListItemButton component={Link} to="/add-professionals"
                                        text_botton="Cadastre-se" >
                                        <ListItemText primary="Cadastre-se" />
                                    </ListItemButton>
                                    <ListItemButton component={Link} to="/login" onClick={handleDrawerToggle}>
                                        <ListItemText primary="Login" />
                                    </ListItemButton>
                                </>
                            ) : (
                                <>
                                    {isUserAdm ? (
                                        <ListItemButton component={Link} to="/controll-panel" onClick={handleDrawerToggle}>
                                            <ListItemText primary="Painel de Controle" />
                                        </ListItemButton>

                                    ) : (
                                        <ListItemButton component={Link} to="/dashboard-user" onClick={handleDrawerToggle}>
                                            <ListItemText primary="Área do Profissional" />
                                        </ListItemButton>
                                    )}

                                    <ListItemButton onClick={() => { handleDrawerToggle(); logoffHandler(); }} >
                                        <ListItemText primary="Sair" />
                                    </ListItemButton>
                                </>
                            )
                            }
                        </List>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
                }}
            >
                {drawer}
            </Drawer>
            <PrivacyPolicyModal open={privacyPolicyOpen} onClose={() => setPrivacyPolicyOpen(false)} />
        </>
    );
};

export default Sidebar;