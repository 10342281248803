import 'bootstrap/dist/css/bootstrap.css';

import { Box } from "@mui/material";
import Carousel from 'react-bootstrap/Carousel';

const Carrossel = ({ carouselItems, isMobile }) => {

    return (
        <Box sx={{
            width: '100vw',
            height: isMobile ? '50vh' : '70vh',
            position: 'relative',
            left: '50%',
            right: '50%',
            marginLeft: '-50vw',
            marginRight: '-50vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,
        }}>
            <Carousel style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', zIndex: 1 }}>

                {carouselItems.map((item, index) => (
                    <Carousel.Item key={index} >
                        <img
                            src={item.img}
                            alt={item.title}
                            style={{
                                width: isMobile ? '100%' : '100%',
                                height: isMobile ? '50vh' : '75vh',
                                objectFit: 'cover',
                                boxShadow: '20px',
                                borderRadius: '20px',
                            }}
                        />
                    </Carousel.Item>
                )
                )}
            </Carousel>
        </Box >

    )
};

export default Carrossel;
