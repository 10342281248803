import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { Container, Grid, Typography, Link, Box, Paper } from '@mui/material';
import { Badge, Person, Group, BarChart, Contacts, MonetizationOn, PhotoSizeSelectActual } from '@mui/icons-material';
import BasePage from '../../components/page_base/PageBase';
import theme from './theme';

const Section = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    transition: 'transform 0.2s',
    '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: theme.shadows[4],
    },
}));

const IconBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
}));

const AdmDashboardPanel = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, []);

    const sections = [
        {
            icon: <Group fontSize="large" />,
            title: "Profissionais",
            links: [
                // { text: "Cadastro", href: "/getFormProfissional" },
                { text: "Pesquisar Todos", href: "/buscarTodosProfissionais" },
                { text: "Pesquisar Últimos Cadastrados", href: "/last-professionals" }
            ]
        },
        {
            icon: <BarChart fontSize="large" />,
            title: "Estatística",
            links: [
                { text: "Contar Profissionais por Estados", href: "/contar-Profissionais-por-estado" },
                { text: "Contar Profissionais por Profissão", href: "/contar-Profissionais-por-profissao" }
            ]
        },
        {
            icon: <PhotoSizeSelectActual fontSize="large" />,
            title: "Banner",
            links: [
                { text: "Cadastro", href: "/cadastrar-banner" },
                { text: "Listagem", href: "#" }
            ]
        },
        {
            icon: <MonetizationOn fontSize="large" />,
            title: "Orçamentos",
            links: [
                { text: "Listar Orçamentos", href: "/listar-todos-orcamentos" }
            ]
        },
        {
            icon: <Badge fontSize="large" />,
            title: "Profissões",
            links: [
                { text: "Cadastro", href: "/cadastrar-profissao" },
                { text: "Listagem", href: "/listar-profissoes" }
            ]
        },
        // {
        //     icon: <Person fontSize="large" />,
        //     title: "Usuários",
        //     links: [
        //         { text: "Cadastro", href: "/getFormUsuario" },
        //         { text: "Listagem", href: "/buscarTodosUsuarios" }
        //     ]
        // },
        // {
        //     icon: <LocationOn fontSize="large" />,
        //     title: "Cidades",
        //     links: [
        //         { text: "Cadastro", href: "/getFormCidade" },
        //         { text: "Listagem", href: "/buscarTodasCidades" }
        //     ]
        // },
        // {
        //     icon: <Contacts fontSize="large" />,
        //     title: "Contatos",
        //     links: [
        //         { text: "Listar Contatos", href: "/buscarContatos" }
        //     ]
        // },

    ];

    const page = (
        <Container sx={{ py: 4, mb: 4 }}>
            <Box sx={{ textAlign: 'center', mb: 4 }}>
                <Typography variant="h2">Painel de Controle</Typography>
            </Box>
            <Grid container spacing={4}>
                {sections.map((section, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4}>
                        <Section>
                            <IconBox>{section.icon}</IconBox>
                            <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                                {section.title}
                            </Typography>
                            {section.links.map((link, idx) => (
                                <Typography key={idx} variant="body2">
                                    <Link href={link.href} underline="none">{link.text}</Link>
                                </Typography>
                            ))}
                        </Section>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );

    return (
        <BasePage
            theme={theme}
            loading={loading}
        >
            {page}
        </BasePage>
    );
};

export default AdmDashboardPanel;
