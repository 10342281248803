import { useState, useEffect } from 'react';
import { Button, CssBaseline, ThemeProvider, Toolbar, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import Header from '../components/Header.jsx';
import Sidebar from '../components/Sidebar.jsx';
import Footer from '../components/Footer.jsx';
import AppRoutes from '../routes/AppRoutes.jsx';

import theme from './theme.jsx';

function MainLayout() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const acceptCookies = () => {
    // Calcula a data de expiração em 24 horas a partir do momento atual
    const expiresDate = new Date();
    expiresDate.setTime(expiresDate.getTime() + (24 * 60 * 60 * 1000)); // Adiciona 24 horas em milissegundos

    // Formata a data de expiração no formato GMT
    const expiresGMT = expiresDate.toGMTString();

    // Define o cookie com a data de expiração em 24 horas
    document.cookie = `cookies_accepted=true; expires=${expiresGMT}; path=/`;

    // Oculta a mensagem de aceitação de cookies após aceitar
    setCookiesAccepted(true);
  };

  useEffect(() => {
    const cookies = document.cookie.split('; ');
    const cookiesAccepted = cookies.find(cookie => cookie.startsWith('cookies_accepted='));
    setCookiesAccepted(!!cookiesAccepted);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Header handleDrawerToggle={handleDrawerToggle} />
        <Sidebar
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - 240px)` },
            mt: 'auto',
            mb: 6,
          }}
        >
          <Toolbar />
          <AppRoutes />
          <Footer />


          {!cookiesAccepted && (
            <Box
              id="cookie-consent"
              sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100%',
                backgroundColor: '#333',
                color: '#fff',
                padding: '10px',
                textAlign: 'center',
              }}
            >
              <Typography variant="body1" sx={{ margin: 0 }}>
                Este site utiliza cookies para garantir uma melhor experiência para você. Ao continuar navegando, você concorda com o uso de cookies.
              </Typography>
              <Button variant="contained" onClick={acceptCookies} sx={{ marginTop: '10px' }}>
                Aceitar cookies
              </Button>
            </Box>
          )}
          {/* {!cookiesAccepted && (
            <div id="cookie-consent" style={{ display: 'block' }}>
              <p>Este site utiliza cookies para garantir uma melhor experiência para você. Ao continuar navegando, você concorda com o uso de cookies.</p>
              <button onClick={acceptCookies}>Aceitar cookies</button>
            </div>
          )} */}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default MainLayout;




// import { useState } from 'react';
// import { CssBaseline, ThemeProvider, Toolbar } from '@mui/material';
// import Box from '@mui/material/Box';

// import Header from '../components/Header.jsx';
// import Sidebar from '../components/Sidebar.jsx';
// import Footer from '../components/Footer.jsx';

// import AppRoutes from '../routes/AppRoutes.jsx';

// import theme from './theme.jsx';

// function acceptCookies() {
//   // Calcula a data de expiração em 24 horas a partir do momento atual
//   var expiresDate = new Date();
//   expiresDate.setTime(expiresDate.getTime() + (24 * 60 * 60 * 1000)); // Adiciona 24 horas em milissegundos

//   // Formata a data de expiração no formato GMT
//   var expiresGMT = expiresDate.toGMTString();

//   // Define o cookie com a data de expiração em 24 horas
//   document.cookie = "cookies_accepted=true; expires=" + expiresGMT + "; path=/";

//   // Oculta a mensagem de aceitação de cookies após aceitar
//   document.getElementById('cookie-consent').style.display = 'none';
// }

// function MainLayout() {
//   const [mobileOpen, setMobileOpen] = useState(false);


//   const handleDrawerToggle = () => {
//     setMobileOpen(!mobileOpen);
//   };

//   return (
//     <ThemeProvider theme={theme}>

//       <Box sx={{ display: 'flex', }}>
//         <CssBaseline />
//         <Header handleDrawerToggle={handleDrawerToggle} />
//         <Sidebar
//           mobileOpen={mobileOpen}
//           handleDrawerToggle={handleDrawerToggle}
//         />
//         <Box
//           component="main"
//           sx={{
//             flexGrow: 1,
//             p: 3,
//             width: { sm: `calc(100% - 240px)` },
//             mt: 'auto', // Isso empurra o conteúdo para cima
//             mb: 6, // Margem na parte inferior do conteúdo principal
//           }}

//         >
//           <Toolbar />

//           <AppRoutes />

//           <Footer />

//           <div id="cookie-consent" style="display: none;">
//             <p>Este site utiliza cookies para garantir uma melhor experiência para você. Ao continuar navegando, você concorda com o uso de cookies.</p>
//             <button onclick={acceptCookies()}>Aceitar cookies</button>
//           </div>

//         </Box>
//       </Box >
//     </ThemeProvider >
//   );
// }

// export default MainLayout;

