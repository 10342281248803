import { Grid, Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

import ExpositorCard from "./ExpositorLogos";

const ListExpostitors = ({ expositors, title }) => {

    return (

        <ThemeProvider theme={theme}>
            <Box sx={{ ...theme.components.MuiBox.styleOverrides.root }}>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="h5" align={'center'}>
                            {title}
                        </Typography>
                        <br />
                        <Grid container spacing={2} justifyContent="center">
                            {expositors.map((sponsor, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <ExpositorCard logo={sponsor.logo} name={sponsor.name} description={sponsor.description} />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default ListExpostitors;