import { createSlice } from "@reduxjs/toolkit";
import api from '../../services/api.service';
import { URL_PUBLICA, URL_API } from "../../constants/constants";

const initialState = {
    professions: [],
    profession: null,
    errorMessage: "",
    successMessage: "",
    pageSize: 50,
    currentPage: 1,
}

const ProfessionsSlice = createSlice({
    name: 'professionsState',
    initialState,
    reducers: {
        setProfessions: (state, { payload }) => {
            const professions = payload;
            return { ...state, professions }
        },
        setProfession: (state, { payload }) => {
            const profession = payload;
            return { ...state, profession }
        },
        setErrorMessage(state, { payload }) {
            const errorMessage = payload;
            return { ...state, errorMessage };
        },
        setSuccessMessage(state, { payload }) {
            const successMessage = payload;
            return { ...state, successMessage };
        },
        setCurrentPage: (state, { payload }) => {
            const currentPage = payload;
            return { ...state, currentPage }
        },
        removeProfession(state, { payload }) {
            const professions = state.professions.filter(profession => profession.id !== payload);
            return { ...state, professions }
        },
        setTotalProfessions: (state, { payload }) => {
            const total = payload;
            return { ...state, total }
        },
    }
});

export const {
    setProfessions,
    setProfession,
    setCurrentPage,
    setErrorMessage,
    removeProfession,
    setSuccessMessage,
    setTotalProfessions,
} = ProfessionsSlice.actions;

export const loadProfessionsWithCount = () => (dispatch) => {

    const URI = `${URL_PUBLICA}/find/professions-with-count`;
    return fetch(URI, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then((data) => {
            // console.log('Data:', data);
            dispatch(setProfessions(data));
        })
        .catch((error) => {
            console.log('Error:', error);
        });
}

export const save = ({ id = null, name, description }) => (dispatch) => {
    try {
        const data = {
            name,
            description,
            icon: "s1.png",
        }

        if (id) {
            data.id = Number(id);
        }

        const URI = `${URL_API}/profession`;
        return fetch(URI, {
            method: 'POST',
            headers: api.getHeaders(),
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((data) => {
                dispatch(setProfession(data));
                dispatch(setSuccessMessage("Procedimento realizado com sucesso."))
            })
            .catch((error) => {
                console.log({ error })
                dispatch(setErrorMessage("Ops, Houve um erro ao realizar o procedimento."));
            });


    } catch (error) {
        console.log({ error })
    }
}

export const loadAllProfessions = (page = 1, pageSize = 50) => (dispatch) => {

    try {

        const offset = (page - 1) * pageSize;
        const URI = `${URL_API}/professions?limit=${pageSize}&offset=${offset}`;
        return fetch(URI, {
            method: 'GET',
            headers: api.getHeaders(),
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log({ data });
                dispatch(setProfessions(data.profissoes));
                dispatch(setTotalProfessions(data.total));
            })
            .catch((error) => {
                console.log({ error })
                dispatch(setProfessions([]));
                dispatch(setTotalProfessions(0));
                dispatch(setErrorMessage("Ops, Houve um erro ao realizar a pesquisa."));
            })
    } catch (error) {
        console.log({ error })
    }
}


export const loadProfessionByID = (id = 0) => (dispatch) => {
    try {

        const URI = `${URL_API}/profession/${id}`;
        return fetch(URI, {
            method: 'GET',
            headers: api.getHeaders(),
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log({ data });
                dispatch(setProfession(data));
            })
            .catch((error) => {
                console.log({ error })
                dispatch(setProfession(null));
                dispatch(setErrorMessage("Ops, Houve um erro ao consultar a profissão."));
            })
    } catch (error) {
        console.log({ error })
    }

}

export const deleteProfession = (id) => (dispatch) => {
    try {
        const intID = Number(id);
        console.log("remover profession => ", intID);
        if (intID) {
            const URI = `${URL_API}/profession/${intID}`;
            return fetch(URI, {
                method: 'DELETE',
                headers: api.getHeaders()
            })
                .then(() => {
                    dispatch(setSuccessMessage("Profissião removida com sucesso."));
                    dispatch(removeProfession(id));

                })
                .catch((error) => {
                    console.log({ error })
                    dispatch(setErrorMessage("Ops, Houve um erro ao remover a profissião."));
                });
        }

    } catch (error) {
        console.log({ error })
    }
}

export default ProfessionsSlice.reducer;

