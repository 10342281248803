import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Card, CardContent, Typography, Button, TextField, InputAdornment, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import BasePage from '../../../../components/page_base/PageBase';
import { exportProfessionalsXLSX, loadAllProfessionals, loadByNameProfessionals, setCurrentPage, disableProfessional, setErrorMessage, setSuccessMessage } from '../../professional.reducer';

import theme from '../theme';
import MakeMessage from '../../../../components/makeMessage/MakeMessage';
import HeadPage from '../../../../components/title/Title';

const FindAllProfessionals = () => {
    const dispatch = useDispatch();
    const professionalState = useSelector((state) => state.professionalState);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedProfessionalOid, setSelectedProfessionalOid] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        dispatch(loadAllProfessionals(professionalState.currentPage, professionalState.pageSize)).then(() => {
            setLoading(false);
        });
    }, [dispatch, professionalState.currentPage, professionalState.pageSize]);

    const handlePageChange = (event, value) => {
        dispatch(setCurrentPage(value));
    };

    const handleExport = () => {
        dispatch(exportProfessionalsXLSX());
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearch = () => {
        setLoading(true);
        dispatch(loadByNameProfessionals(searchTerm)).then(() => {
            setLoading(false);
        });
    };

    const handleLoadAll = () => {
        setLoading(true);
        setSearchTerm("");
        dispatch(loadAllProfessionals(professionalState.currentPage, professionalState.pageSize)).then(() => {
            setLoading(false);
        });
    };


    const onCloseMessage = () => {
        dispatch(setErrorMessage(""));
        dispatch(setSuccessMessage(""));
    };

    const handleOpen = (oid) => {
        setSelectedProfessionalOid(oid);
        setOpen(true); // Abre o modal
    };

    const handleClose = () => {
        setOpen(false); // Fecha o modal
        setSelectedProfessionalOid(null);
    };

    const handleConfirmDelete = () => {
        if (selectedProfessionalOid) {
            dispatch(disableProfessional(selectedProfessionalOid)); // Executa a função de desativação
        }
        handleClose(); // Fecha o modal após a confirmação
    };

    const page = (

        <>
            <HeadPage title={`Pesquisar por todos os profissionais`} />

            <Box display="flex" justifyContent="space-around" mb={3}>
                <Card>
                    <CardContent>
                        <Box textAlign="center">
                            <i className="bi bi-people-fill" style={{ fontSize: '2rem' }}></i>
                            <Typography variant="h5" mt={2}>Total de Profissionais</Typography>
                            <Typography variant="h3" color="error">
                                {professionalState.total}
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <Box textAlign="center">
                            <i className="bi bi-filetype-xlsx" style={{ fontSize: '2rem' }}></i>
                            <Typography variant="h5" mt={2}>Planilha Excel</Typography>
                            <Button variant="contained" color="primary" onClick={handleExport}>
                                Exportar Profissionais
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Box>

            <Box display="flex" justifyContent="space-between" alignItems="center" boxShadow={3} borderRadius={5} p={2} mb={2} style={{ backgroundColor: 'white' }}>
                <TextField
                    label="Pesquisar pelo nome"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button variant="contained" color="primary" onClick={handleSearch} disabled={!searchTerm}>
                                    <SearchIcon />
                                </Button>
                            </InputAdornment>
                        ),
                    }}
                    style={{ flexGrow: 1, marginRight: '16px' }}
                />
                <Button variant="contained" color="error" onClick={handleLoadAll} style={{ color: 'white' }}>
                    Buscar Todos
                </Button>
            </Box>

            {professionalState.errorMessage && (
                <MakeMessage
                    typeMessage="error"
                    message={professionalState.errorMessage}
                    onClose={onCloseMessage}
                />
            )}

            {professionalState.successMessage && (
                <MakeMessage
                    typeMessage="success"
                    message={professionalState.successMessage}
                    onClose={onCloseMessage}
                />
            )}

            <TableContainer component={Paper}>
                <Table>
                    <TableHead variant="head" style={{ backgroundColor: '#1976d2', color: 'white' }}>
                        <TableRow>
                            <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>ID</TableCell>
                            <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Nome</TableCell>
                            <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Email</TableCell>
                            <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Profissões</TableCell>
                            <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Telefone</TableCell>
                            <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Estado / Cidade</TableCell>
                            <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }} align='center' >Ação</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {professionalState.professionals && professionalState.professionals.length > 0 ? (
                            professionalState.professionals.map((professional, index) => (
                                <TableRow key={professional.oid} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                    <TableCell>{professional.oid}</TableCell>
                                    <TableCell>{professional.nome.toUpperCase()}</TableCell>
                                    <TableCell>{professional.email}</TableCell>
                                    <TableCell>
                                        {professional.profissoes ? professional.profissoes.map(prof => prof.nome).join(', ') : ''}
                                    </TableCell>
                                    <TableCell>{professional.telefone}</TableCell>
                                    <TableCell>{professional.cidade.uf} - {professional.cidade.nome}</TableCell>
                                    <TableCell align='center'>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            title={`Desativar o profissional ${professional.nome}`}
                                            onClick={() => handleOpen(professional.oid)}
                                        >
                                            <DeleteIcon />
                                        </Button>
                                    </TableCell>
                                </TableRow>


                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    Nenhum profissional encontrado
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <Pagination
                    count={Math.ceil(professionalState.total / professionalState.pageSize)}
                    page={professionalState.currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    style={{ marginTop: 16, marginBottom: 16 }}
                />
            </TableContainer>

            {/* Modal de Confirmação */}
            < Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Confirmação de Desativação</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Tem certeza que deseja desativar o profissional?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant='contained'>
                        Cancelar
                    </Button>
                    <Button onClick={handleConfirmDelete} color="error" variant='contained' autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    );

    return (
        <BasePage theme={theme} loading={loading}>
            {page}
        </BasePage>
    );
};

export default FindAllProfessionals;
