import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, TextField, Typography, FormControl, FormControlLabel, Checkbox, Grid, MenuItem, InputLabel, Select, InputAdornment, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import BasePage from '../../../../components/page_base/PageBase';
import LGPDModal from '../../../../components/modal/LGPDModal';
import MakeMessage from '../../../../components/makeMessage/MakeMessage';

import { loadCities, loadProfessions, save, setErrorMessage, setSuccessMessage, loadProfessionalByID } from '../../professional.reducer';
import theme from '../theme';
import utils from '../../../utils';
import HeadPage from '../../../../components/title/Title';

const FormProfessional = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const professionalState = useSelector((state) => state.professionalState);
    const professional = professionalState.professional || null;

    const [uf, setUf] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [cityId, setCityId] = useState('');
    const [loading, setLoading] = useState(true);
    const [password, setPassword] = useState('');
    const [telephone, setTelephone] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [lgpdAceito, setLgpdAceito] = useState('não');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [professionsSelected, setProfessionsSelected] = useState([]);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [successMessageVisible, setSuccessMessageVisible] = useState(false);

    useEffect(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        dispatch(loadProfessions());
        if (id) {
            dispatch(loadProfessionalByID(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (professional && id) {
            const { nome, email, telefone, lgpdAceito, cidade, profissoes } = professional;
            setName(nome);
            setEmail(email);
            setTelephone(telefone);
            setLgpdAceito(lgpdAceito);
            if (cidade) {
                setCityId(cidade?.oid || '');
                setUf(cidade?.uf || '');
                const selectedProfessionIds = profissoes.map(profissao => profissao.oid);
                setProfessionsSelected(selectedProfessionIds);
                dispatch(loadCities(cidade.uf));
            }
        }
    }, [professional, id, dispatch]);

    useEffect(() => {
        if (professionalState.successMessage) {
            // Abre o WhatsApp ao cadastrar
            window.open("https://api.whatsapp.com/send?phone=5514991665023&text=Olá, Me cadastrei e gostaria de mais informações.", "_blank");

            // Exibir mensagem de sucesso
            setSuccessMessageVisible(true);

            // Configurar o timeout para esconder a mensagem após 10 segundos
            const timer = setTimeout(() => {
                dispatch(setSuccessMessage(""));
                setSuccessMessageVisible(false);
            }, 10000);

            return () => clearTimeout(timer);
        }
    }, [professionalState.successMessage, dispatch]);

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleModalAgree = () => {
        setLgpdAceito('sim');
        setModalOpen(false);
        dispatch(setErrorMessage(""));
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handleTelephoneChange = (event) => {
        setTelephone(utils.formatPhoneNumber(event.target.value));
    };

    const onChangeState = (e) => {
        setUf(e.target.value);
        dispatch(loadCities(e.target.value));
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const onCloseMessage = () => {
        dispatch(setErrorMessage(""));
        dispatch(setSuccessMessage(""));
        setSuccessMessageVisible(false);
    };

    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };

    const validateForm = () => {
        return name && email && telephone && cityId && password && lgpdAceito === 'sim' && professionsSelected.length > 0;
    };

    const onSubmitForm = () => {
        if (!id) {
            if (validateForm()) {
                if (password !== confirmPassword) {
                    setErrorModalOpen(true);
                } else {
                    setLoading(true);
                    dispatch(save({ id, name, email, telephone, cityId, lgpdAceito, password, professionIds: professionsSelected }))
                        .then(() => {
                            setLoading(false);
                            setLgpdAceito('não');
                        })
                        .catch(() => {
                            setLoading(false);
                        });
                }
            } else {
                dispatch(setErrorMessage("Todos os campos são obrigatórios ou você não aceitou os termos."));
            }
        } else {
            if (name && email && telephone && cityId && professionsSelected.length > 0) {
                dispatch(save({ id, name, email, telephone, cityId, professionIds: professionsSelected }))
                    .then(() => {
                        setLoading(false);
                        setLgpdAceito('não');
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            }
        }

    };

    const handleProfessionChange = (id) => {
        setProfessionsSelected((prev) => prev.includes(id) ? prev.filter(selectedId => selectedId !== id) : [...prev, id]);
    };

    const page = (
        <>
            <HeadPage title={id ? "Editar Profissional" : "Cadastrar Profissional"} />

            {professionalState.errorMessage && (
                <MakeMessage
                    typeMessage="error"
                    message={professionalState.errorMessage}
                    onClose={onCloseMessage}
                />
            )}

            {successMessageVisible && professionalState.successMessage && (
                <MakeMessage
                    typeMessage="success"
                    message={professionalState.successMessage}
                    onClose={onCloseMessage}
                />
            )}

            {!id && (
                <Typography variant="body1" color={'red'} gutterBottom>
                    Para realizar seu cadastro, leia o termo de aceitação - LGPD.
                </Typography>
            )}

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} mb={8}>
                <TextField fullWidth name="nome" label="Nome" variant="outlined" value={name} onChange={handleNameChange} required />
                <TextField fullWidth name="email" label="E-mail" variant="outlined" value={email} onChange={handleEmailChange} required />
                <TextField fullWidth name="telephone" label="Telefone Comercial" variant="outlined" value={telephone} onChange={handleTelephoneChange} required />
                {!id && (
                    <>
                        <TextField fullWidth type={showPassword ? "text" : "password"} name="senha" label="Senha de acesso para plataforma" variant="outlined" value={password} onChange={handlePasswordChange} required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleTogglePasswordVisibility}>
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} />
                        <TextField fullWidth type={showPassword ? "text" : "password"} name="confirmarSenha" label="Confirmar Senha" variant="outlined" value={confirmPassword} onChange={handleConfirmPasswordChange} required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleTogglePasswordVisibility}>
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} />
                    </>
                )}
                <FormControl fullWidth required>
                    <InputLabel id="estado-label">Estado</InputLabel>
                    <Select labelId="estado-label" value={uf} onChange={onChangeState} label="Estado" name="uf">
                        <MenuItem value=""><em>Estado</em></MenuItem>
                        {professionalState.states && professionalState.states.map((state, index) => (
                            <MenuItem key={index + 1} value={state.name}>{state.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth required>
                    <InputLabel id="cidade-label">Cidade</InputLabel>
                    <Select
                        labelId="cidade-label"
                        value={cityId}
                        onChange={(e) => setCityId(e.target.value)}
                        label="Cidade"
                        name="cidadeId"
                        native
                    >
                        <option value="">Cidade</option>
                        {professionalState.cities.map((city) => (
                            <option key={city.id} value={city.id}>
                                {city.name}
                            </option>
                        ))}
                        {/* <MenuItem value=""><em>Cidade</em></MenuItem>
                        {professionalState.cities && professionalState.cities.map((city, index) => (
                            <MenuItem key={index} value={city.id}>{city.name}</MenuItem>
                        ))} */}
                    </Select>
                </FormControl>

                <Box sx={{ mt: 2, p: 2, border: '1px solid #ccc', borderRadius: 2 }}>
                    <FormControl fullWidth required component="fieldset">
                        <Typography variant="body1">Profissões</Typography>
                        <Grid container spacing={2}>
                            {professionalState.professions && professionalState.professions.map((profession) => (
                                <Grid item xs={12} sm={6} md={4} key={profession.id}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={professionsSelected.includes(profession.id)}
                                                onChange={() => handleProfessionChange(profession.id)}
                                                name={profession.name}
                                            />
                                        }
                                        label={profession.name}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </FormControl>
                </Box>

                {!id && (
                    <Box>
                        <Button variant="text" onClick={() => setModalOpen(true)}>
                            Ler termo de aceitação - LGPD
                        </Button>
                        <input type="hidden" id="lgpdAceito" name="lgpdAceito" value={lgpdAceito} />
                    </Box>
                )}
                <Button type="button" variant="contained" color="primary" onClick={onSubmitForm}>
                    {id ? "Atualizar" : "Cadastre-se e fale conosco"}
                </Button>

                <Dialog open={errorModalOpen} onClose={handleErrorModalClose}>
                    <DialogTitle>Erro</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            As senhas não coincidem. Por favor, verifique.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleErrorModalClose} color="primary">Fechar</Button>
                    </DialogActions>
                </Dialog>
            </Box>

            <LGPDModal open={modalOpen} onClose={handleModalClose} onAgree={handleModalAgree} />
        </>
    );

    return (
        <BasePage theme={theme} loading={loading}>
            {page}
        </BasePage>
    );
};

export default FormProfessional;
