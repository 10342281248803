import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const LGPDModal = ({ open, onClose, onAgree }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="privacy-policy-title"
            aria-describedby="privacy-policy-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90%', sm: '80%', md: '70%' },
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    maxHeight: '90vh',
                    overflow: 'auto',
                }}
            >
                <Typography id="privacy-policy-title" variant="h6" component="h2">
                    Termo de Aceitação - LGPD
                </Typography>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Termo de Responsabilidade e Isenção de Responsabilidade por Dados Fornecidos - LGPD
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Por favor, leia atentamente o seguinte termo antes de prosseguir:
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Eu, o Cliente, ao utilizar os serviços fornecidos pela plataforma digital CONSTRUIR MAIS BARATO, reconheço e concordo com os termos e condições estabelecidos neste documento.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Responsabilidade pelos Dados Fornecidos:</strong><br />
                        Eu reconheço e concordo que sou totalmente responsável por quaisquer dados pessoais, informações ou conteúdos que eu solicite, receba ou de qualquer forma obtenha através da plataforma CONSTRUIR MAIS BARATO.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Isenção de Responsabilidade da Plataforma:</strong><br />
                        De acordo com a Lei Geral de Proteção de Dados (LGPD), a plataforma CONSTRUIR MAIS BARATO não se responsabiliza pelo uso indevido, tratamento inadequado ou violações de dados pessoais que possam ocorrer em relação aos dados fornecidos ao Cliente. A responsabilidade pela proteção e tratamento adequado dos dados pessoais é exclusivamente do Cliente.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Finalidade e Consentimento:</strong><br />
                        Eu reconheço que a CONSTRUIR MAIS BARATO pode coletar, armazenar e utilizar meus dados pessoais conforme necessário para a prestação de serviços ou cumprimento de obrigações contratuais, desde que em conformidade com as disposições da LGPD e mediante consentimento explícito do titular dos dados, quando aplicável.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Segurança dos Dados:</strong><br />
                        A CONSTRUIR MAIS BARATO adota medidas técnicas e organizacionais adequadas para proteger os dados pessoais contra acesso não autorizado, uso indevido, divulgação, alteração e destruição não autorizados, em conformidade com as disposições da LGPD.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Direitos dos Titulares dos Dados:</strong><br />
                        Eu reconheço e concordo em respeitar os direitos dos titulares dos dados, conforme previsto na LGPD, incluindo o direito de acesso, retificação, exclusão, anonimização, portabilidade e revogação do consentimento.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Indenização:</strong><br />
                        Eu concordo em indenizar e isentar a CONSTRUIR MAIS BARATO, seus diretores, funcionários e agentes de qualquer responsabilidade, perda, reclamação ou despesa (incluindo honorários advocatícios razoáveis) decorrentes ou relacionados com o tratamento de dados pessoais pelo Cliente ou com o uso da plataforma.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Ao clicar no botão "Concordo", eu confirmo que li, entendi e concordo com os termos e condições estabelecidos neste Termo de Responsabilidade e Isenção de Responsabilidade por Dados Fornecidos.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Ao clicar em "Concordo", você estará dando consentimento para os termos acima mencionados.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Este termo entra em vigor na data do clique no botão "Concordo" e permanecerá em pleno vigor e efeito durante o período de utilização dos serviços da plataforma CONSTRUIR MAIS BARATO pelo Cliente.
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button onClick={onClose} variant="contained" color="error" sx={{ mr: 2 }}>
                        Sair
                    </Button>
                    <Button onClick={onAgree} variant="contained" color="primary">
                        Concordo e Aceito
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default LGPDModal;
